import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding-top: 4.8rem;

  button {
    width: 4.8rem;
    height: 4.8rem;
    background: url(/images/icons/to_top.png) no-repeat center / cover;
    border: 0;
    text-indent: -100000px;
    cursor: pointer;
  }
`;

export default function GoToTop() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Container>
      <button onClick={handleClick} type="button">toTop</button>
    </Container>
  );
}
