import styled from 'styled-components';
import { useEffect } from 'react';
import Article from '../../models/Article';
import Article1 from './articles/Article1';
import Article2 from './articles/Article2';
import Article3 from './articles/Article3';
import useOGReplacer from '../../hooks/useOGReplacer';

const Container = styled.div`
  margin-block: 6.4rem ${(props) => props.theme.sizes.inlineOffset};
  color: rgba(0, 0, 0, 1);


  ul {
    list-style: disc;
    padding-left: ${(props) => props.theme.sizes.inlineOffset};
    margin-bottom: 1rem;
  }

  p, li {
    word-break: break-all;
    line-height: 1.3
  }
  
  p > span {
    display: block;
  }

  a {
    text-decoration: underline;
  }

  .header-text {
    margin-bottom: 2.4rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: 10.5rem;
    

    .header-text {
      margin-bottom: 4.8rem;
    }
  }
`;

type ArticlesProps = {
  id: number;
}

const articles: {
  [k: number]: React.JSX.Element,
} = {
  1: Article1(),
  2: Article2(),
  3: Article3(),
};

export default function Article({ id }: ArticlesProps) {
  const article = articles[id];

  if (!article) {
    return null;
  }

  const { toBookmark } = useOGReplacer();

  useEffect(() => {
    toBookmark();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <Container>
      {article}
    </Container>
  );
}
