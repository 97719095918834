const articleDatas = [
  {
    id: 1,
    title: '外国人登録証とは❓',
    url: '/images/article/article1_cover.jpg',
    description: '外国人登録証とは、ビザを取得して韓国に滞在する外国人の身分を証明する重要な書類です。留学にかかわらず結婚、ワーキングホリデー、仕事や出張など９０日以上韓国に滞在する場合は発行が義務付けられています。外国人登録証はお酒を飲む際や、病院に行く際にも必須のため、日常的に持ち歩くことを推奨します🌷',
    tags: ['外国人登録証', '必要書類', '発行手続き'],
  },
  {
    id: 2,
    title: 'これを見れば完璧⁈目指せ韓国留学持ち物マスター😎',
    url: 'https://res.klook.com/image/upload/q_85/c_fill,w_1360/v1677527253/blog/fjommcbzk6gzralrwrdk.webp',
    description: '今回は韓国留学で準備するもの、あると便利な必須アイテムについて紹介していきます。',
    tags: ['韓国留学', '持ち物リスト', '必需品'],
  },
];

export default articleDatas;
