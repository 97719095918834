import styled from 'styled-components';
import AcademyItem from '../../models/AcademyItem';
import SelectBox from './SelectBox';
import useCompareRegion from '../../hooks/useCompareRegion';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    gap: 3rem;
  }
`;

const Image = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-block: 2rem; 
`;

type SelectBoxesProps = {
  academies: AcademyItem[];
  selected1: number | null;
  selected2: number | null;
  handleClick1: (id:number | null) => void;
  handleClick2: (id:number | null) => void;
}

export default function SelectBoxes({
  academies, selected1, selected2, handleClick1, handleClick2,
}: SelectBoxesProps) {
  const { region: region1, changeRegion: changeRegion1 } = useCompareRegion();
  const { region: region2, changeRegion: changeRegion2 } = useCompareRegion();

  return (
    <Container>
      <SelectBox
        academies={academies}
        changeRegion={changeRegion1}
        region={region1}
        select={handleClick1}
        selected={selected1}
      />
      <Image src="/images/icons/switch.png" />
      <SelectBox
        academies={academies.filter((a) => a.id !== selected1)}
        changeRegion={changeRegion2}
        region={region2}
        select={handleClick2}
        selected={selected2}
      />
    </Container>
  );
}
