import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import Area from '../components/Area';

import Header from '../components/Header';
import Footer from '../components/Footer';

import useFetchArea from '../hooks/useFetchArea';

export default function AreaPage() {
  const { id } = useParams();

  const { isLoading, error, data: area } = useFetchArea(
    { id: id || '' },
  );

  if (isLoading) {
    return null;
  }

  if (error || !area) {
    return <div>Error</div>;
  }

  return (
    <>
      <Helmet>
        <title>{`韓国で人気のある地域${area.name || ''} | CLIK'}`}</title>
        <meta name="description" content={`韓国の地域情報を交通、住居、利便性の面で簡単に比較することができます。 該当地域の公共交通情報と該当地域の住居地に対する簡単な説明、そして必ず必要な便宜施設を確認することができます。 地元の人気スポットをぜひお見逃しなく！ ${area.introduction || ''}`} />
        <meta name="keywords" content={`${area.name.split('\n')[0]}, 交通, 住宅, 利便性, 大学, 地図, Googleマップ, 地域情報, ホットスポット, 繁華街`} />
      </Helmet>
      <Header />
      <Area area={area} />
      <Footer />
    </>
  );
}
