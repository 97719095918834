import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';

import useFetchAreas from '../hooks/useFetchAreas';
import Areas from '../components/Areas';

export default function AreasPage() {
  const { isLoading, error, data: areas } = useFetchAreas();

  if (isLoading) {
    return null;
  }

  if (error || !areas) {
    return <div>Error</div>;
  }

  const areasString = areas.map((a) => a.name[0]).join(', ');

  return (
    <>
      <Helmet>
        <title>地域一覧 | CLIK</title>
        <meta name="description" content="韓国にどんな地域があるのか確認してみてください。 地域ごとに異なる特徴を一目で簡単に確認できます！" />
        <meta name="keywords" content={`地域情報, 交通, 住居, 便利さ, 大学, 地図, Google Maps, 人気スポット, 繁華街, 地域, ${areasString}`} />
      </Helmet>
      <Header />
      <Areas areas={areas} />
      <Footer />
    </>
  );
}
