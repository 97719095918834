import styled from 'styled-components';

const Container = styled.footer`
  padding: 4rem ${(props) => props.theme.sizes.inlineOffset};
  background: #F0F0F0;

  dl {
    font-size: 1.4rem;
    font-weight: 500;
    margin-block: 4rem;
    color: #909090;
  }

  dd {
    margin-top: .5rem;
    margin-bottom: 1.6rem;
  }

  small {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 1.6rem;
    color: #909090;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-inline: calc((100% - ${(props) => props.theme.sizes.layoutMaxWidth}) / 2)
  }
`;

const Logo = styled.span`
  display: inline-block;
  width: 7.6rem;
  aspect-ratio: 76/24;
  text-indent: -10000px;
  background: url(/images/logo.png) no-repeat center / cover;
`;

type LinkType = {
  url: string;
}

const LinkButton = styled.a<LinkType>`
  display: inline-block;
  margin-right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  background: url(${(props) => props.url}) no-repeat center / cover;
  border-radius: 50%;
`;

export default function Footer() {
  return (
    <Container>
      <Logo>CLIK</Logo>
      <dl>
        <dt>お問い合わせ</dt>
        <dd>clik.jpn@gmail.com</dd>
      </dl>
      <LinkButton
        url="/images/icons/insta.png"
        href="https://www.instagram.com/clik_jp/"
        target="_blank"
        rel="noreferrer"
      />
      {/* <LinkButton url="/images/icons/youtube.png" /> */}
      <small>Copyright © Clik. All Rights Reserved</small>
    </Container>
  );
}
