import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ArticleModel from '../../models/Article';

const Container = styled.div`
  margin-block: 0 ${(props) => props.theme.sizes.inlineOffset};

  h2 {
    font-size: 3.2rem;
    font-weight: 700;
    padding-top: ${(props) => props.theme.sizes.inlineOffset};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-inline: 0;

    h2 {
      font-size: 4rem;
    }
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;

  .article {
    padding-block: 2rem;
    width: 100%;
    border-bottom: 1px solid rgba(240, 240, 240, 1);

  }
  

  :last-of-type {
    border: 0;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;

    .article {
      width: 49%;
      border-bottom: 1px solid rgba(240, 240, 240, 1);

    }
  }
`;

const Article = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  .contents {
    width: 60%;
    padding-block: 2.4rem;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 2.4rem;
    margin: 0;
    margin-bottom: 1rem;
    line-height: 1.4
  }

  ul {
    font-size: 1.4rem;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    color: rgba(153, 153, 153, 1);
  }

  img {
    width: 14rem;
    height: 10rem;
    border-radius: 2rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    justify-content: space-between;
    .contents {
      width: 45%;
      padding-block: 2.4rem;
    }
  
    img {
      width: 20rem;
      height: 14rem;
    }
  }
`;

type ArticlesProps = {
  articles: ArticleModel[];
  showAll?: boolean;
}

export default function Articles({ articles, showAll = true }: ArticlesProps) {
  const list = showAll ? articles : articles.slice(0, 8);
  return (
    <Container>
      <List>
        {list.map((a) => (
          <li className="article" key={a.id}>
            <Article to={`/article/${a.id}`}>
              <div className="contents">
                <h3>{a.title}</h3>
                <ul>
                  {a.tags.map((t) => <li key={t}>{`#${t}`}</li>)}
                </ul>
              </div>
              <img src={a.url} alt="" />
            </Article>
          </li>
        ))}
      </List>
    </Container>
  );
}
