import { useState } from 'react';

import { styled } from 'styled-components';

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 300/200;
  border-radius: .4rem;
  overflow: hidden;
`;

type ImageProp = {
  show: boolean;
}

const DefaultImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #D9D9D9;
`;

const Image = styled.img<ImageProp>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LoadingImage = styled.img`
  width: 80%;
  aspect-ratio: 180/70;
`;

type ImageProps = {
  url: string;
}

export default function Image1({ url }: ImageProps) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <ImageContainer>
      {!imageLoaded && (
        <DefaultImage>
          <LoadingImage src="/images/image-loading.png" />
        </DefaultImage>
      )}
      <Image src={url} alt="" onLoad={handleImageLoaded} show={imageLoaded} />
    </ImageContainer>
  );
}
