import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Article from '../components/Article';

export default function ArticlePage() {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>韓国留学ガイド | CLIK</title>
        <meta name="keywords" content="韓国留学, 留学情報, 留学ガイド, 留学準備物, 外国人登録証, ビザ書類" />
        <meta name="description" content="韓国留学を計画している方は必須情報を確認してください。外国人登録証、ビザ書類、留学準備物リストなど、留学生のための完全ガイドを提供します。" />
      </Helmet>
      <Header />
      <Article id={+id} />
      <Footer />
    </>
  );
}
