import { useLocation } from 'react-router-dom';

import { useEffect, useRef } from 'react';
import StickyTab from './StickyHeader';

import Program from './Program';

import usePrograms from '../../hooks/usePrograms';

import { Program as ProgramModel } from '../../models/Academy';

export default function Programs({ programs }: {programs: ProgramModel[]}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const programId = searchParams.get('programId') || '';

  const year = searchParams.get('year') || '';

  const initId = programId ? +programId : programs[0].id;

  const { selectedTabId, handleClick } = usePrograms({ initId });
  const program = programs.find((p) => p.id === selectedTabId);

  const scheduleId = searchParams.get('scheduleId') || '';

  const tab = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (programId) {
      tab.current?.scrollIntoView();
    }
  }, [programId, year, scheduleId, selectedTabId, program]);

  if (!program) {
    return <div>Error</div>;
  }

  return (
    <div
      ref={tab}
    >
      <StickyTab
        programs={programs}
        selectedTabId={selectedTabId}
        handleClick={handleClick}
      />
      <Program selectedYear={year} selectedId={scheduleId} program={program} />
    </div>
  );
}
