import styled from 'styled-components';

import Contents from '../common/Contents';

import TopSection from '../common/TopSection';
import NearAreas from '../common/NearAreas';

import NearAcademies from './NearAcademies';
import Places from './Places';
import Transport from './Transport';
import Residence from './Residence';
import Facility from './Facility';

import Area from '../../models/Area';
import Map from '../common/Map';
import Title from '../common/Title';

const Container = styled.div`
  margin-top: 6.4rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: 15.5rem;
  }
`;

const Introduction = styled.div`
  padding: ${(props) => props.theme.sizes.inlineOffset};
  padding-block: 4rem;
  border-bottom: 1rem solid #F0F0F0;
  border-top: 1.6rem solid rgba(240, 240, 240, 1);

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-inline: 0;
    border-bottom: 0;
    gap: 2.4rem;
    border-top: 0;

    &> div {
      display: inline-block;
      margin-right: 2%;
      width: 49%;
    }

    &>p {
      font-size: 1.8rem;
      display: inline-block;
      width: 49%;
      vertical-align: top;
    }
  }
`;

const NoMarginContents = styled(Contents)`
  margin-bottom: 0;
`;

const Grades = styled.dl`
  display: flex;
  gap: 2rem;
  padding: 4rem ${(props) => props.theme.sizes.inlineOffset};
  overflow-x: scroll;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: 8rem 0 4rem;
  }
`;

type ColorBoxProps = {
  color: string;
};

const ColorBox = styled.div<ColorBoxProps>`
  padding: 2rem;
  border-radius: 2rem;
  background: ${(props) => props.color};

  dt {
    font-size: 1.8rem;
    width: 7rem;
  }

  dd {
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: 1.5rem;
  }
`;

const point = {
  transport: '交通',
  residence: '住居',
  facility: '利便性',
};

const color = {
  transport: 'rgba(255, 229, 229, 1)',
  residence: 'rgba(255, 246, 229, 1)',
  facility: 'rgba(229, 255, 238, 1)',
};

function getGrade(value: number) {
  switch (true) {
  case value === 1: return 'Bad';
  case value === 2 || value === 3: return 'Soso';
  case value === 4 || value === 5: return 'Good';
  default: {
    return '';
  }
  }
}

export default function Area({ area }: {area: Area}) {
  const { points, nearAcademies, nearAreas } = area;

  const [name1, name2] = area.name.split('\n');
  return (
    <Container>
      <TopSection imageUrl={area.imageUrl} name1={name1} name2={name2} />
      <Grades>
        {Object.entries(area.points).map(([k, { score }]) => (
          <ColorBox key={k} color={color[k]}>
            <dt>{point[k]}</dt>
            <dd>{getGrade(score)}</dd>
          </ColorBox>
        ))}
      </Grades>
      <Introduction>
        <Title>
          はじめに
        </Title>
        <Map lat={area.lat} lon={area.lon} />
        <NoMarginContents>{area.introduction}</NoMarginContents>
      </Introduction>
      <NearAcademies academies={nearAcademies} />
      <Transport transport={points.transport} />
      <Residence residence={points.residence} />
      <Facility facility={points.facility} />
      <Places places={area.places} />
      {nearAreas.length ? <NearAreas areas={nearAreas} /> : null}
    </Container>
  );
}
