import styled from 'styled-components';
import Section from '../common/Section';
import Title from '../common/Title';
import { Facility } from '../../models/Area';

const Facilities = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

type ColorBoxProps = {
  color: string;
  url: string;
};

const ColorBox = styled.li<ColorBoxProps>`
  border-radius: 2rem;
  background: ${(props) => props.color};

  a {
    display: inline-block;
    padding: 2rem;
    padding-top: 8.8rem;
    width: 15rem;
    background:  url(${(props) => props.url}) no-repeat 20% 30% / 3rem;

    span {
      padding-right: 2.5rem;
      background: url(/images/icons/link.png) no-repeat right center / 2rem;
    }
  }
`;

const iconCategory = [
  {
    category: ['ホームプラス', 'Eマート', 'ロッテマート'],
    icon: '/images/icons/cart.png',
    color: 'rgba(255, 229, 229, 1)',
  },
  {
    category: ['エンターシックス'],
    icon: '/images/icons/dress.png',
    color: 'rgba(255, 238, 239, 1)',
  },
  {
    category: ['ダイソー'],
    icon: '/images/icons/clips.png',
    color: 'rgba(238, 255, 255, 1)',
  },
  {
    category: ['オリーブヤング'],
    icon: '/images/icons/lipstick.png',
    color: 'rgba(229, 255, 238, 1)',
  },
  {
    category: ['スターバックス'],
    icon: '/images/icons/coffee.png',
    color: 'rgba(255, 246, 229, 1)',
  },
  {
    category: ['ロッテ百貨店', '現代百貨店', 'AKプラザ百貨店'],
    icon: '/images/icons/building.png',
    color: 'rgba(229, 241, 255, 1)',
  },
  {
    category: ['教保文庫', '永豊文庫'],
    icon: '/images/icons/books.png',
    color: 'rgba(255, 238, 255, 1)',
  },
  {
    category: ['広蔵市場'],
    icon: '/images/icons/smile.png',
    color: 'rgba(255, 238, 255, 1)',
  },
];

export default function Facility({ facility }: {facility: Facility}) {
  return (
    <Section>
      <Title>便利な店</Title>
      <Facilities>
        {facility.list.map((f) => {
          const { icon, color } = iconCategory.find((c) => c.category.includes(f.name))!;
          return (
            <ColorBox color="red" key={f.name} url={icon} color={color}>
              <a href={f.url} target="_blank" rel="noreferrer"><span>{f.name}</span></a>
            </ColorBox>
          );
        })}
      </Facilities>
    </Section>
  );
}
