import styled from 'styled-components';

import Contents from '../common/Contents';
import Section from '../common/Section';
import Title from '../common/Title';
import { Residence } from '../../models/Area';

const Districts = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.inlineOffset};

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
  }
`;

const District = styled.li`
  flex: 1;
  padding: ${(props) => props.theme.sizes.inlineOffset};
  background: #F8F8F8;
  border: 1px solid #E5E5E5;
  border-radius: 2rem;

  h3 {
    font-weight: 700;
  }

  .contents {
    margin-top: 1rem;
    line-height: 1.5;
    word-break: break-all;
  }

  .price {
    font-weight: 700;
    display: block;
    margin-top: 1rem;
    text-align: right;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex: none;
    padding-block: 4rem;
    width: 32%;
  }
`;
export default function Residence({ residence }: {residence: Residence}) {
  return (
    <Section>
      <Title>住居</Title>
      <Contents>{residence.introduction}</Contents>
      <Districts>
        {residence.districts.map((district) => (
          <District key={`${district.name}`}>
            <h3>{district.name}</h3>
            <div className="contents">{district.contents}</div>
          </District>
        ))}
      </Districts>
    </Section>
  );
}
