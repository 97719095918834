import styled from 'styled-components';

import useStickyTab from '../../hooks/useStickyTab';

import { Program } from '../../models/Academy';

type ContainerProps = {
  isSticky: boolean;
}

const Container = styled.div<ContainerProps>`
  z-index: 1;
  position: ${(props) => (props.isSticky ? 'fixed' : 'relative')};
  ${(props) => props.isSticky && 'top: 64px'};
  padding-inline: ${(props) => props.theme.sizes.inlineOffset};
  width: 100%;
  border-bottom: 1px solid #CCCCCC;
  background: #FFFFFF;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    ${(props) => props.isSticky && 'top: 75px; left: 0'};  
    padding-inline: 0;
  }
`;

type TabsProps = {
  isSticky: boolean;
}

const Tabs = styled.ul<TabsProps>`
  display: flex;
  gap: 1em;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    ${(props) => props.isSticky && `margin-inline: calc((100% - ${props.theme.sizes.layoutMaxWidth}) /2)`}; 
  }
`;

type TabProps = {
  selected: boolean;
}

const Tab = styled.li<TabProps>`
  font-size: 1.4rem;
  font-weight: 700;
  padding-block: 1.9rem;
  color: ${(props) => (props.selected ? '#000000' : '#999999')};
  ${(props) => (props.selected && 'border-bottom: 2px solid #000000')};
  cursor: pointer;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: 2.4rem;
  }
`;

type StickyTabProps = {
  programs: Program[],
  selectedTabId: number;
  handleClick: (number: number) => void;
};

export default function StickyTab({
  programs,
  selectedTabId, handleClick,
}: StickyTabProps) {
  const { isSticky, tabRef } = useStickyTab();

  return (
    <Container
      isSticky={isSticky}
      ref={tabRef}
    >
      <Tabs isSticky={isSticky}>
        {programs.map((p) => (
          <Tab
            key={p.id}
            selected={p.id === selectedTabId}
            onClick={() => handleClick(p.id)}
          >
            {p.name}
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
}
