import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-top: 1px solid #e5e5e5; 
  border-bottom:  1px solid #e5e5e5; 
`;

type LabelProps = {
  selected: boolean;
};

const Label = styled.label<LabelProps>`
  padding: 2rem 2rem;
  color: ${(props) => (props.selected ? 'rgba(171, 31, 35, 1)' : 'rgba(0, 0, 0, 0.4)')};
  cursor: pointer;

  input {
    display: none;
  }
`;

type RadioButtonProps = {
  options: string[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selected: string;
}

export default function RadioButton({ options, handleChange, selected }:RadioButtonProps) {
  return (
    <Container>
      {
        options.map((o) => (
          <Label key={o} selected={o === selected}>
            <input
              type="radio"
              value={o}
              checked={o === selected}
              onChange={handleChange}
            />
            {o}
          </Label>
        ))
      }
    </Container>
  );
}
