import styled from 'styled-components';

import { Location } from '../../models/Academy';

import Section from '../common/Section';
import Title from '../common/Title';
import Contents from '../common/Contents';
import Map from '../common/Map';

const Container = styled(Section)`
  h4 {
    font-weight: 700;
    margin-top: 2rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;

    h3 {
      grid-column: 1 / 5;
      grid-row: 1 / 2;
    }

    h4 {
      font-size: 1.8rem;
    }
  }
`;

const IntroductionWrapper = styled(Contents)`
  margin-bottom: 2rem;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: 1.8rem;
    grid-column: 3 / 5;
    grid-row: 2 / 3;
  }
`;

const MapWrapper = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    grid-column: 1 / 3;
    grid-row: 2 / 5;
    margin-right: 3rem;
  }
`;

const ContentWrapper = styled.div`
  grid-column: 3 / 5;
  grid-row: 3 / 4;
`;

const NoMarginContents = styled(Contents)`
  margin-block: 1rem 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: 1.8rem;
  }
   
`;

type LocationType = {
  introduction: string;
  location: Location;
}

export default function Introduction({ introduction, location }: LocationType) {
  return (
    <Container>
      <Title>はじめに</Title>
      <IntroductionWrapper>{introduction}</IntroductionWrapper>
      {/* <Wrapper> */}
      <MapWrapper>
        <Map lat={location.lat} lon={location.lon} />
      </MapWrapper>
      <ContentWrapper>
        <h4>場所</h4>
        <NoMarginContents>{location.address}</NoMarginContents>
      </ContentWrapper>
      {/* </Wrapper> */}
    </Container>
  );
}
