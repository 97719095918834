/* eslint-disable class-methods-use-this */
import axios from 'axios';

import Area from '../models/Area';
import Academy from '../models/Academy';
import Schedule from '../models/Schedule';
import AcademyItem from '../models/AcademyItem';
import CompareProgramType from '../models/CompareProgramType';
import CompareAcademyResult from '../models/CompareAcademyResult';
import OgData from '../models/OgData';

const baseURL = process.env.API_BASE_URL || 'http://localhost:8000';

export default class ApiService {
  async fetchArea(id: number): Promise<Area> {
    const path = `areas/${id}`;
    const url = `${baseURL}/${path}`;

    const { data } = await axios.get(url);

    const { area } = data;

    return area;
  }

  async fetchAreas(count: number | undefined): Promise<Area[]> {
    const path = 'areas';
    const url = `${baseURL}/${path}${count ? `?count=${count}` : ''}`;

    const { data } = await axios.get(url);

    const { areas } = data;

    return areas;
  }

  async fetchAcademy(id: number): Promise<Academy> {
    const path = `academies/${id}`;
    const url = `${baseURL}/${path}`;

    const { data } = await axios.get(url);

    const { academy } = data;

    return academy;
  }

  async fetchAcademies(count: number | undefined): Promise<Academy[]> {
    const path = 'academies';
    const url = `${baseURL}/${path}${count ? `?count=${count}` : ''}`;

    const { data } = await axios.get(url);

    const { academies } = data;

    return academies;
  }

  async fetchSchedules(): Promise<Schedule[]> {
    const path = 'schedules';
    const url = `${baseURL}/${path}`;

    const { data } = await axios.get(url);

    const { schedules } = data;

    return schedules;
  }

  async fetchCompareAcademies(): Promise<AcademyItem[]> {
    const path = 'compare-academies';
    const url = `${baseURL}/${path}`;
    const { data } = await axios.get(url);

    const { academies } = data;

    return academies;
  }

  async fetchCompareAcademyResult(
    id1: number | null,
    id2: number | null,
    programType: CompareProgramType,
    voted: boolean,
  ): Promise<CompareAcademyResult> {
    const path = 'compare-academies';
    const url = `${baseURL}/${path}`;

    const { data } = await axios.post(url, {
      academyIds: [id1, id2],
      programType,
      voted,
    });

    return data;
  }

  async voteAcademy({
    id1, id2, programType, selectedId,
  }: {
    id1: number,
    id2: number,
    programType: CompareProgramType,
    selectedId: number,
  }) {
    const path = 'compare-like';
    const url = `${baseURL}/${path}`;

    const { data } = await axios.post(url, {
      academyIds: [id1, id2],
      programType,
      selectedId,
    });

    return data;
  }

  async fetchOgData(link: string): Promise<OgData> {
    const path = 'fetch-og-data';
    const url = `${baseURL}/${path}`;
    const response = await axios.post(url, { link });
    const parser = new DOMParser();
    const doc = parser.parseFromString(response.data, 'text/html');

    const getMetaContent = (property: string) => {
      const element = doc.querySelector(`meta[property='${property}']`);
      return element ? element.getAttribute('content') || '' : '';
    };

    function extractPath(url) {
      const regex = /https?:\/\/[^\/]+(\/[^?]*)/;
      const matches = url.match(regex);
      return matches ? matches[1] : null;
    }

    const getFavicon = () => {
      const element = doc.querySelector('link[rel*="icon"]') as HTMLLinkElement;
      return element ? extractPath(element.href) : '';
    };

    return {
      title: getMetaContent('og:title') || doc.title,
      type: getMetaContent('og:type'),
      image: getMetaContent('og:image'),
      url: getMetaContent('og:url'),
      description: getMetaContent('og:description') || getMetaContent('description'),
      siteName: getMetaContent('og:site_name'),
      favicon: getFavicon(),
    };
  }
}

export const apiService = new ApiService();
