import { useState } from 'react';

import styled from 'styled-components';

import SmallTitle from '../common/Small';

import { IncludedSchedule } from '../../models/Academy';

const GrayRoundBox = styled.div`
  margin-block: 2rem 4rem;
  padding: ${(props) => props.theme.sizes.inlineOffset};
  border-radius: 3.2rem;
  background: #F8F8F8;
  border: 1px solid rgba(229, 229, 229, 1);

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-bottom: 8rem;
    padding: 4rem;
  }
`;

const Tabs = styled.ul`
  display: flex;
  gap: 1.2rem;
`;

type TabProps = {
  selected: boolean;
}

const Tab = styled.li<TabProps>`
  font-size: 1.4rem;
  display: inline-block;
  padding: .4rem .8rem;
  border-radius: .7rem;
  color: ${(props) => (props.selected ? '#000000' : '#999999')};
  border: ${(props) => (props.selected ? 2 : 1)}px solid ${(props) => (props.selected ? '#AB1F23' : '#999999')};
  cursor: pointer;
`;

const Semesters = styled.ul`
  display: flex;
  flex-direction: column;

  :first-child {
    h4 {
      border: 0;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Semester = styled.li`
  &:first-child > h5 {
    padding-top: 0;
    border-top: 0;
  }

  &:last-child > dl {
    margin-bottom: 0;
  }

  h5 {
    font-weight: 700;
    padding-block: 1.6rem;
    cursor: pointer;
    border-top: 1px solid #D8D8D8;
    color: rgba(153, 153, 153, 1);
  }

  dl {
    margin-bottom: 1rem;
  }
  
  dt, dd {
    display: inline-block;
    line-height: 2;
  }

  dt {
    font-weight: 700;
    width: 25%;
  }

  dd {
    font-size: 1.6rem;
    font-weight: 400;
    width: 65%;
    text-align: left;
    color: rgba(0, 0, 0, 1);
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: 1.8rem;
    width: 50%;

    h5 {
      padding-top: 0;
      border-top: 0;
    }

    dl {
      margin-bottom: 2rem;
    }

    dt {
      width: 20%;
    }

    &:nth-child(3),
    &:nth-child(4) {
      border-top: 1px solid rgba(229, 229, 229, 1);

      h5 {
        padding-top: 2rem;
      }
    }
  }
`;

type SchedulesProps = {
  schedules: {
    [key: string]: IncludedSchedule[]
  },
  selectedYear: string;
  selectedId: string;
}

export default function Schedules({ selectedYear, selectedId, schedules }: SchedulesProps) {
  const years = Object.keys(schedules);

  const [year, setSelectedYear] = useState(selectedYear || years[0]);
  const semesters = schedules[+year];

  const initId = selectedId ? +selectedId : semesters[0].id;

  const [opens, setOpens] = useState<number[]>([initId]);

  const handleClickSemester = (id: number) => {
    if (opens.some((o) => o === id)) {
      setOpens([...opens.filter((o) => o !== id)]);
    } else {
      setOpens([...opens, id]);
    }
  };

  const handleClick = (value: string) => {
    setSelectedYear(value);
  };
  return (
    <>
      <SmallTitle>日程</SmallTitle>
      <Tabs>
        {years.map((s) => (
          <Tab
            key={s}
            selected={s === year}
            onClick={() => handleClick(s)}
          >
            {`${s}年`}
          </Tab>
        ))}
      </Tabs>
      <GrayRoundBox>
        <Semesters>
          {semesters.map((s) => (
            <Semester
              key={s.id}
              onClick={() => handleClickSemester(s.id)}
            >
              <h5>{s.name}</h5>
              <dl>
                <dt>申請期間</dt>
                <dd>{`${s.applicationStartDate ? s.applicationStartDate : ''} - ${s.applicationEndDate}`}</dd>
                <dt>授業期間</dt>
                <dd>{`${s.programStartDate} - ${s.programEndDate}`}</dd>
              </dl>
            </Semester>
          ))}
        </Semesters>
      </GrayRoundBox>
    </>
  );
}
