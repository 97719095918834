import { useState } from 'react';
import CompareProgramType from '../models/CompareProgramType';

export default function useCompareProgramType() {
  const [programType, setProgramType] = useState<CompareProgramType>('正規課程');

  const clickLeft = () => {
    setProgramType('正規課程');
  };

  const clickRight = () => {
    setProgramType('短期コース');
  };

  const flip = () => {
    setProgramType(programType === '正規課程' ? '短期コース' : '正規課程');
  };

  return {
    programType, clickLeft, clickRight, flip,
  };
}
