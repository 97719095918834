import styled from 'styled-components';
import Academy from '../../models/Academy';
import Cards2 from '../common/Cards2';

const Container = styled.div`
  margin-block: 6.4rem ${(props) => props.theme.sizes.inlineOffset};
  padding-inline: ${(props) => props.theme.sizes.inlineOffset};

  h2 {
    font-size: 3.2rem;
    font-weight: 700;
    padding-block: ${(props) => props.theme.sizes.inlineOffset};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: 7.6rem;

    h2 {
      font-size: 4.8rem;
    }
  }
`;

type AcademiesProps = {
  academies: Academy[];
}

export default function Academies({ academies }: AcademiesProps) {
  const cards = academies.map((a) => ({
    id: a.id,
    title: a.name.split('\n')[0],
    subTitle: a.name.split('\n')[1],
    imageUrl: a.imageUrl,
  }));
  return (
    <Container>
      <h2>어학당</h2>
      <Cards2 category="academy" cards={cards} />
    </Container>
  );
}
