import styled from 'styled-components';
import Cards from './Cards';
import Section from './Section';
import Title from './Title';
import GoToTop from './GoToTop';

import Area from '../../models/Area';

const NoBorderSection = styled(Section)`
  border-bottom: 0;
`;

type NearAreasProps = {
  areas: Area[]
}

export default function NearAreas({ areas }: NearAreasProps) {
  const cards = areas.map(({
    id, name, imageUrl,
  }) => ({
    id,
    title: name.split('\n')[0],
    subTitle: name.split('\n')[1],
    imageUrl,
  }));

  return (
    <NoBorderSection>
      <Title>近くの語学堂</Title>
      <Cards category="area" cards={cards} />
      <GoToTop />
    </NoBorderSection>
  );
}
