import styled from 'styled-components';
import {
  ChangeEvent, MouseEvent, useEffect, useRef,
} from 'react';
import AcademyItem from '../../models/AcademyItem';
import Region from '../../models/Region';
import Options from './Options';
import useCompareSelect from '../../hooks/useCompareSelect';

type ContainerProps = {
  open: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: 37rem;
  background: #FFFFFF;
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid #E5E5E5;
  ${(props) => props.open && 'border-bottom: 0'};
  ${(props) => props.open && 'border-radius: 1rem 1rem 0 0'};
`;

type PaneProps = {
  selected: boolean;
}

const Pane = styled.div<PaneProps>`
  display: flex;
  align-items: center;
  padding: 2rem;
  color: ${(props) => (props.selected ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.4)')};
  background: url(/images/icons/arrow_bottom.png) no-repeat calc(100% - 5%) center / 1.2rem;
`;

const inSeoul = [
  '淑明女子大学語学堂',
  '延世大学語学堂',
  '東国大学校語学堂',
  '西江大学校語学堂',
  '漢陽大学語学堂',
  '西京大学校語学堂',
  'ソウル市立大学語学堂',
  '建国大学語学堂',
  '崇実大学語学堂',
  '慶熙大学語学堂',
  '成均館大学校語学堂',
  '弘益大学校語学堂',
  '明知大学語学堂',
  '国民大学校語学堂',
  'ソウル大学語学堂',
  '韓国外国語大学校',
  '梨花女子大学語学堂',
  '高麗大学校語学堂',
];

type SelectBoxesProps = {
  academies: AcademyItem[]
  changeRegion: (event: ChangeEvent<HTMLInputElement>) => void;
  region: Region;
  selected: number | null;
  select: (number: number | null) => void;
}

export default function SelectBox({
  academies, changeRegion, region, selected, select,
}: SelectBoxesProps) {
  const { open, flipOpen, close } = useCompareSelect();

  const selectBoxRef = useRef(null);

  const handleClose = (e: MouseEvent) => {
    if (selectBoxRef.current && !selectBoxRef.current.contains(e.target as Node)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClose);
    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
  }, []);

  const list = region === 'ソウル' ? academies.filter((a) => inSeoul.some((i) => (a.name.includes(i))))
    : academies.filter((a) => inSeoul.every((i) => !(a.name.includes(i))));

  const selectedAcademy = list.find((i) => i.id === selected)?.name.split('\n')[0];

  const handleClickOption = (id: number) => {
    flipOpen();
    select(id);
  };

  const handleRegionChange = (event: ChangeEvent<HTMLInputElement>) => {
    select(null);
    changeRegion(event);
  };

  return (
    <Container ref={selectBoxRef} open={open}>
      <Pane onClick={flipOpen} selected={!!selected}>
        {!selected ? '語学堂を選んでください。' : selectedAcademy || '語学堂を選んでください。' }
      </Pane>
      {open && (
        <Options
          academies={list}
          handleRegionChange={handleRegionChange}
          region={region}
          select={handleClickOption}
        />
      )}
    </Container>
  );
}
