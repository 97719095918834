import styled from 'styled-components';

const Percentage = styled.div`
  font-size: 3.6rem;
  font-weight: 700;
  margin-block: 1rem;
  color: #FFFFFF;
  line-height: 1.5;
`;

const Count = styled.div`
  font-weight: 700;
  color: #FFFFFF;
`;

type ScoreProps = {
  percentage: number;
  count: number;
}

export default function Score({ percentage, count }: ScoreProps) {
  return (
    <>
      <Percentage>{`${percentage}%`}</Percentage>
      <Count>{`${count}표`}</Count>
    </>
  );
}
