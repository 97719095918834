import styled from 'styled-components';
import Toggle from './Toggle';
import CompareProgramType from '../../models/CompareProgramType';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ButtonProps = {
  selected: boolean;
}

const Button = styled.button<ButtonProps>`
  background: none;
  border: 0;
  color: ${(props) => (props.selected ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.4)')};
  cursor: pointer;
`;

type ProgramProps = {
  clickLeft: ()=>void;
  clickRight: ()=>void;
  flip: ()=>void;
  selected: CompareProgramType;
}

export default function Program({
  clickLeft, clickRight, selected, flip,
}:ProgramProps) {
  return (
    <Container>
      <Button type="button" onClick={clickLeft} selected={selected === '正規課程'}>正規課程</Button>
      <Toggle status={selected === '正規課程'} handleClick={flip} />
      <Button type="button" onClick={clickRight} selected={selected === '短期コース'}>短期コース</Button>
    </Container>
  );
}
