import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.ul`
  display: flex;
  gap: 1rem;
  overflow: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    gap: 2%;
  }
`;

type CardProps = {
  url: string;
}

const Card = styled.li<CardProps>`
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${(props) => props.url}) no-repeat center / cover;
  border-radius: 2rem;

  a {
    display: inline-block;
    width: 30rem;
    height: 100%;
    padding: 1.5rem;
    padding-top: 14rem;
    color: #FFFFFF;
  }

  h3 {
    font-weight: 700;
    margin-bottom: 1rem;
  }


  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: 1.8rem;
    width: 30%;

    h3 {
      font-size: 2rem;
    }
  }
`;

type CardModel = {
  id: number;
  title: string;
  subTitle: string;
  imageUrl: string;
}

type CardsProps = {
  category: string;
  cards: CardModel[];
};

export default function Cards({ category, cards }: CardsProps) {
  return (
    <Container>
      {cards.map((card) => (
        <Card key={card.id} url={card.imageUrl}>
          <Link to={`/${category}/${card.id}`}>
            <h3>{card.title}</h3>
            <div>{card.subTitle}</div>
          </Link>
        </Card>
      ))}
    </Container>
  );
}
