import { useState } from 'react';

export default function useMenu() {
  const [shown, setShown] = useState(false);

  return {
    shown,
    setShown,
  };
}
