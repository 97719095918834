import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';

import useFetchAcademies from '../hooks/useFetchAcademies';
import Academies from '../components/Aacademies';

export default function AcademiesPage() {
  const { isLoading, error, data: academies } = useFetchAcademies();

  if (isLoading) {
    return null;
  }

  if (error || !academies) {
    return null;
  }

  const academiesString = academies.map((a) => a.name.split('\n')[0]).join(', ');

  return (
    <>
      <Helmet>
        <title>韓国語語学堂一覧 | CLIK</title>
        <meta name="description" content="韓国語を学ぶために韓国語の語学や留学を計画しているなら、どんな語学堂があるのか調べてみてください！" />
        <meta name="keywords" content={`${academiesString}, 韓国語語学堂, 韓国語教育機関, 言語プログラム, 韓国語学習, 留学生, 韓国語教育, ソウル, 教育課程, 言語教育, 文化体験, 言語授業, 韓国語勉強, 留学生, 韓国文化, 言語プログラム登録, 語学堂の特徴`} />
      </Helmet>
      <Header />
      <Academies academies={academies} />
      <Footer />
    </>
  );
}
