import styled from 'styled-components';

import AcademyModel from '../../models/Academy';

import TopSection from '../common/TopSection';
import NearAreas from '../common/NearAreas';

import Prides from './Prides';
import Overview from './Overview';
import Programs from './Programs';
import Introduction from './Introduction';

const Container = styled.div`
  margin-top: 6.4rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: 15.7rem;
  }
`;

const Wrapper = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    gap: 2rem;
  }
`;

type AcademyProps = {
  academy: AcademyModel
}

export default function Academy({ academy }: AcademyProps) {
  const [name1, name2] = academy.name.split('\n');
  return (
    <Container>
      <TopSection imageUrl={academy.imageUrl} name1={name1} name2={name2} />
      <Wrapper>
        <Overview overview={academy.overview} />
        <Introduction introduction={academy.overview.introduction} location={academy.location} />
      </Wrapper>
      <Prides prides={academy.prides} />
      <Programs programs={academy.programs} />
      {academy.nearAreas.length ? <NearAreas areas={academy.nearAreas} /> : null}
    </Container>
  );
}
