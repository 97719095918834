import { useQuery } from '@tanstack/react-query';

import Area from '../models/Area';

import { apiService } from '../services/ApiService';

export default function useFetchArea({ id }:
  { id: string }) {
  const { isLoading, error, data } = useQuery<Area>({
    queryKey: ['area'],
    queryFn: () => apiService.fetchArea(+id),
  });

  return { isLoading, error, data };
}
