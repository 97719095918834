import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import App from './App';

const fonts = {
  NotoSansJapanese: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap',
};

function createFontLinkElement(url: string) {
  const element = document.createElement('link');
  element.rel = 'stylesheet';
  element.type = 'text/css';
  element.href = url;
  return element;
}

function setupFonts() {
  Object.values(fonts).forEach((font) => document.head.appendChild(createFontLinkElement(font)));
}

function Root() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

function main() {
  setupFonts();

  const container = document.getElementById('app');
  if (!container) {
    // TODO: log error
    return;
  }

  const root = ReactDOM.createRoot(container);
  root.render(<Root />);
}

main();
