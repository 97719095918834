import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-inline: 2rem;
  width: 2.8rem;
  height: 1.5rem;
  background: rgba(171, 31, 35, 1);
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

type BallProps = {
  status: boolean;
}

const Ball = styled.div<BallProps>`
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  background: #FFFFFF;
  border-radius: 50%;
  transform: translateX(${(props) => (props.status ? '-' : '+')}50%);
`;

type ToggleProps = {
  status: boolean;
  handleClick: () => void;
}

export default function Toggle({ status, handleClick }: ToggleProps) {
  return (
    <Container>
      <Ball status={status} onClick={handleClick} />
    </Container>
  );
}
