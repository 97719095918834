import styled from 'styled-components';

const Section = styled.div`
  padding: 4rem ${(props) => props.theme.sizes.inlineOffset};
  border-bottom: 1rem solid #F0F0F0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-inline: 0;
    border: 0;
  }
`;

export default Section;
