import { ChangeEvent, useState } from 'react';
import Region from '../models/Region';

export default function useCompareRegion() {
  const [region, setRegion] = useState<Region>('ソウル');

  const changeRegion = (event: ChangeEvent<HTMLInputElement>) => {
    setRegion(event.target.value as Region);
  };

  return {
    region, changeRegion,
  };
}
