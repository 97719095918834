/* eslint-disable max-len */

import { Helmet } from 'react-helmet';

import ArticleHeader from '../../../styles/ArticleHeader';
import ArticleArea from '../../../styles/ArticleArea';

export default function Article2() {
  const title = 'これを見れば完璧⁈目指せ韓国留学持ち物マスター😎';
  const description = 'これを見れば完璧⁈目指せ韓国留学持ち物マスター😎留学持ち物リスト完全版🌟';
  const url = 'https://res.klook.com/image/upload/q_85/c_fill,w_1360/v1677527253/blog/fjommcbzk6gzralrwrdk.webp';
  const tags = ['韓国留学', '持ち物リスト', '必需品', '留学準備', '韓国生活'];
  const date = '2024.06.24';
  return (
    <ArticleArea>
      <Helmet>
        <meta name="description" content={description} />
        <meta name="keywords" content={tags.join(', ')} />
        <title>{`${title} | CLIK`}</title>
      </Helmet>
      <ArticleHeader url={url}>
        <div className="date">{date}</div>
        <h2>{title}</h2>
        <ul className="tags">
          {tags.map((t) => (<li key={t}>{`#${t}`}</li>))}
        </ul>
      </ArticleHeader>
      <p className="header-text">今回は韓国留学で準備するもの、あると便利な必須アイテムについて紹介していきます。</p>
      <section>
        <h3>必需品</h3>
        <ul className="emphasis">
          <li>パスポート</li>
          <li>ビザ関連書類</li>
          <li>航空券</li>
          <li>入学許可証(念のため準備しておくことを推奨)</li>
          <li>海外保険の証明書(長期留学は保険が必須なところも多いため保険に加入された方は持参することを推奨)</li>
          <li>現金</li>
          <li>クレジットカード</li>
          <li>携帯電話＆充電器</li>
        </ul>
      </section>

      <section>
        <h3>持ち物 電子機器類</h3>
        <ul className="emphasis">
          <li>パソコン＆充電器(必須ではないですが中級クラス以上になると授業で使用する機会もあるため持参することを推奨)</li>
          <li>変換プラグ(CタイプorSEタイプ）</li>
          <li>延長コード</li>
          <li>ヘアアイロン(海外仕様可能か要確認)</li>
          <li>ヘアドライヤー(海外使用可能か要確認)</li>
          <li>モバイルバッテリー</li>
        </ul>
      </section>

      <section>
        <h3>持ち物　日用品</h3>
        <p>日用品は基本的に韓国で購入できるものも多いため、すぐ必要なものや普段から愛用しているものだけ持参することをおすすめします😊</p>
        <ul className="emphasis">
          <li>シャワーセット(シャンプー、コンディショナー、ボディーソープなど)</li>
          <li>メイク落とし＆洗顔セット(クレンジング、洗顔フォームなど)</li>
          <li>歯磨きセット</li>
          <li>スキンケア用品</li>
          <li>コンタクト＆洗浄液、メガネ(必要な方のみ）</li>
          <li>カミソリ</li>
          <li>常備薬(生理痛の薬など普段から愛用しているものがあれば持参することを推奨)</li>
          <li>ポケットティッシュ、ウエットティッシュ</li>
          <li>タオル類</li>
          <li>雨具</li>
          <li>生理用品</li>
          <li>勉強道具</li>
          <li>洗濯用品</li>
        </ul>
      </section>

      <section>
        <h3>持ち物　衣類編</h3>
        <p>私の場合は普段着を数着持参し、洋服などは韓国の方が日本より安く販売しているため必要な衣類は現地調達しました🙋</p>
        <ul className="emphasis">
          <li>普段着</li>
          <li>下着</li>
          <li>部屋着</li>
          <li>靴下</li>
          <li>防寒着(必要であれば)</li>
          <li>靴、サンダル</li>
          <li>カバン</li>
        </ul>
      </section>

      <section>
        <h3>私が留学中に後悔した日本の必須アイテム</h3>
        <ul className="emphasis">
          <li>日傘 - 韓国の日傘は裏地がついていないものも見かけるので私は日本から持参すればよかったと後悔しました💦</li>
          <li>トイレットペーパー - 韓国はトイレットペーパーの値段が日本の約２～３倍するため、何個か日本から持参することをおすすめします。</li>
          <li>日本の食品＆調味料 - 料理をされる方は必須!!韓国で購入できるものもありますが値段が高いためよく食べる日本の食品やよく使う調味料は日本から持参することをおすすめします。私は持参して行きましたが、もっとたくさん持っていけばよかった後悔しました笑</li>
          <li>虫よけグッズ - 韓国の蚊は日本の蚊より刺されると痛痒いです😖日本の方が値段も安価なため、予防のためにも日本から持参することをおすすめします。</li>
        </ul>
      </section>

      <section>
        <h3>私的必需品ランキング</h3>
        <ol>
          <li>生理用品 - 日本の生理用品の方が質が良く、安価なため、まとめ買いして韓国に持っていきました。</li>
          <li>常備薬 - 私は生理痛の薬や風邪薬、トローチなどを持参して行きました。</li>
          <li>日本の食品＆調味料 - 外食ばかりだとお金がかかるのと、意外と日本食が恋しくなるので、必須アイテムでした:)</li>
        </ol>
      </section>

      <section>
        <h3 className="underline">最後に🌸</h3>
        <p>基本的には韓国で調達できるものが多いため忘れ物があっても大丈夫‼何とかなります(経験談笑)ただ日本オリジナル商品(食品や調味料など)は韓国で売っていないものも多々…😭購入できるものも日本より価格が高いため、お気に入りの食品や調味料があれば日本から持参されることをおすすめします。</p>
      </section>
    </ArticleArea>
  );
}
