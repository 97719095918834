import useFetchCompareResult from './useFetchCompareResult';
import CompareProgramType from '../models/CompareProgramType';

export default function useCompareResult({
  id1, id2, programType, voted,
}: {
  id1: number | null;
  id2: number | null;
  programType: CompareProgramType;
  voted: boolean;
}) {
  const { isLoading, data: result } = useFetchCompareResult({
    id1, id2, programType, voted,
  });

  return {
    result,
  };
}
