import ImageBasedBorad from '../common/ImageBasedBoard';
import Section from '../common/Section';
import Title from '../common/Title';

export default function Prides({ prides }: {prides: [{ name: string, imageUrl: string }]}) {
  return (
    <Section>
      <Title>語学堂の特長</Title>
      <ImageBasedBorad contents={prides} />
    </Section>
  );
}
