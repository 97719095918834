import styled from 'styled-components';

import { Overview } from '../../models/Academy';

import Price from './Price';

const Container = styled.div`
  padding: 4rem ${(props) => props.theme.sizes.inlineOffset};
  border-bottom: 1rem solid #F0F0F0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex: 1;
    border-bottom: 0;
    padding-inline: 0;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem ${(props) => props.theme.sizes.inlineOffset} 0;
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 3.2rem;
  background: #F8F8F8;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-inline: 4rem;
  }
`;

const ColumnDl = styled.dl`
  display: flex;
  flex-wrap: wrap;
  padding-top: 6rem;
  border-top: 1px solid rgba(229, 229, 229, 1);

  > div {
    width: 50%;
    margin-bottom: 4rem;
  }

  > div:nth-of-type(3),
  > div:nth-of-type(4) {
    margin-top: 4rem;
  }

  dt {
    position: relative;
    font-weight: 700;
    margin-bottom: 1rem;
    color: rgba(153, 153, 153, 1);
  }

  dt:before {
    content: '';
    position: absolute;
    top: -3.5rem;
    width: 2rem;
    height: 2rem;
  }

  dt.dormitory:before {
    background: url(/images/icons/home.svg) no-repeat center / contain;
  }

  dt.scholarship:before {
    background: url(/images/icons/graduation.svg) no-repeat center / contain;
  }

  dt.grade:before {
    background: url(/images/icons/flag.svg) no-repeat center / contain;
  }

  dt.relationship:before {
    background: url(/images/icons/people.svg) no-repeat center / contain;
  }

  dd {
    font-size: 2.8rem;
    font-weight: 700;
  }
    
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-wrap: nowrap;
    padding-top: 8rem;

    > div {
      margin-top: 0 !important;
    }
  }
`;

export default function Overview({ overview }: {overview: Overview}) {
  return (
    <Container>
      <Box>
        <dl>
          {overview.fees.map(({ name, amount }) => (
            <Price key={name} name={name} amount={amount} />
          ))}
        </dl>
        <ColumnDl>
          <div>
            <dt className="dormitory">寮</dt>
            <dd>{overview.dormitory}</dd>
          </div>
          <div>
            <dt className="scholarship">奨学金</dt>
            <dd>{overview.scholarship}</dd>
          </div>
          <div>
            <dt className="grade">韓国立地</dt>
            <dd>{overview.rank}</dd>
          </div>
          <div>
            <dt className="relationship">交流</dt>
            <dd>{overview.relationship}</dd>
          </div>
        </ColumnDl>
      </Box>
    </Container>
  );
}
