import styled from 'styled-components';

 type BigImageType = {
  height: string;
}

const BigImage = styled.img<BigImageType>`
  width: 100%;
  height: ${(props) => props.height};
  border-radius: 2rem;
`;

export default BigImage;
