import styled from 'styled-components';
import Popularity from '../../../models/Popularity';
import Score from './Score';

const Button = styled.button`
  width: 10rem;
  margin-top: 2rem;
  padding: 1rem 2rem;
  background: rgba(171, 31, 35, 1);
  border-radius: 1rem;
  color: #FFFFFF;
  border: 0;
`;

const getTotal = ({ p1, p2 }: {
  p1: Popularity | null, p2:Popularity | null
}) => {
  const p1Count = p1?.likedCount || 0;
  const p2Count = p2?.likedCount || 0;
  const total = p1Count + p2Count;

  return [
    { percentage: (p1Count / total) * 100, count: p1Count },
    { percentage: (p2Count / total) * 100, count: p2Count },
  ];
};

type ContentsProps = {
  academyId: number;
  popularities: Popularity[];
  isFirst: boolean;
  vote: (id: number) => void;
  loading: boolean;
};

export default function Contents({
  academyId, popularities, isFirst, vote, loading,
}: ContentsProps) {
  if (!popularities) {
    return (<Button disabled={loading} type="button" onClick={() => vote(academyId)}>投票する</Button>);
  }

  const [first, second] = getTotal({ p1: popularities[0], p2: popularities[1] });

  const { percentage, count } = isFirst ? first : second;

  return (
    <Score percentage={percentage} count={count} />
  );
}
