import { useQuery } from '@tanstack/react-query';

import { apiService } from '../services/ApiService';

import CompareAcademyResult from '../models/CompareAcademyResult';
import CompareProgramType from '../models/CompareProgramType';

export default function useFetchCompareResult({
  id1, id2, programType, voted,
}: {
  id1: number | null;
  id2: number | null;
  programType: CompareProgramType;
  voted: boolean;
}) {
  const {
    isLoading, error, data, refetch,
  } = useQuery<CompareAcademyResult>({
    queryKey: ['academies', id1, id2, programType],
    queryFn: () => apiService.fetchCompareAcademyResult(id1, id2, programType, voted),
    enabled: !!id1 && !!id2,
  });

  return {
    isLoading, error, data, refetch,
  };
}
