import styled from 'styled-components';
import PopularityModel from '../../../models/Popularity';
import AcademyItem from '../../../models/AcademyItem';
import CompareProgramType from '../../../models/CompareProgramType';
import useVoteAcademy from '../../../hooks/useVoteAcademy';
import Contents from './Contents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12rem;
`;

const Title = styled.div`
  font-size: 2.8rem;
  font-weight: 700;
  width: 31rem;
  text-align: center;
  line-height: 1.5;
  word-break: break-all;
  white-space: pre-wrap
`;

const Academies = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;  
  margin-top: 4rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
  }
`;

type AcademyProps = {
  url: string;
}

const Academy = styled.div<AcademyProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 24rem;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${(props) => props.url}) no-repeat center / cover;
  
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  &:nth-child(2) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 0;
    }
    
    &:nth-child(2) {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 10px;
    }
  }
`;

const Name = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #FFFFFF;
`;

type VoteProps = {
  academies: AcademyItem[];
  popularities: PopularityModel[];
  programType: CompareProgramType
}

export default function Vote({
  academies, popularities: initPopularities, programType,
}: VoteProps) {
  const { popularities, vote, loading } = useVoteAcademy({ initPopularities });

  const handleClickVote = async (id: number) => {
    await vote(academies[0].id, academies[1].id, programType, id);
  };

  return (
    <Container>
      <Title>{'他の人の選択は？\n投票して結果を確認してください！'}</Title>
      <Academies>
        <Academy url={academies[0].imageUrl}>
          <Name>{academies[0].name.split('\n')[0]}</Name>
          <Contents
            academyId={academies[0].id}
            popularities={popularities}
            vote={handleClickVote}
            isFirst
            loading={loading}
          />
        </Academy>
        <Academy url={academies[1].imageUrl}>
          <Name>{academies[1].name.split('\n')[0]}</Name>
          <Contents
            academyId={academies[1].id}
            popularities={popularities}
            vote={handleClickVote}
            isFirst={false}
            loading={loading}
          />
        </Academy>
      </Academies>
    </Container>
  );
}
