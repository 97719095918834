import styled from 'styled-components';

type ContentsType = {
  marginBottom?: boolean | null;
}

const Contents = styled.p<ContentsType>`
  margin-block: ${(props) => props.theme.sizes.inlineOffset};
  margin-bottom: ${(props) => (!props.marginBottom ? '4rem' : 0)};
  line-height: 1.5;
  word-break: break-all;
`;

export default Contents;
