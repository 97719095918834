import { useState } from 'react';
import CompareProgramType from '../models/CompareProgramType';
import { apiService } from '../services/ApiService';
import Popularity from '../models/Popularity';

export default function useVoteAcademy({
  initPopularities,
}: {
  initPopularities: Popularity[];
}) {
  const [popularities, setPopularities] = useState<Popularity[]>(initPopularities);

  const [loading, setLoading] = useState(false);

  const vote = async (
    id1: number,
    id2: number,
    programType: CompareProgramType,
    selectedId: number,
  ) => {
    setLoading(true);
    const result = await apiService.voteAcademy({
      id1, id2, programType, selectedId,
    });

    const { popularites } = result;

    setPopularities(popularites);

    setLoading(false);
  };

  return {
    vote,
    popularities,
    loading,
  };
}
