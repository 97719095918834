import styled from 'styled-components';

import Image1 from './Image1';

const Places = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 5%;

  li {
    margin-bottom: 1.6rem;
  }
`;

const Place = styled.li`
  width: 47.5%;
  line-height: 1.5;

  h4 {
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 1rem;
    word-break: break-all;
  }

  a {
    padding-right: 2.1rem;
    background: url(/images/icons/link.png) no-repeat right 35% / 1.6rem;
  }
  
  p {
    font-size: .7em;
    word-break: break-all;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 30%;
    
    h4 {
      font-size: 1.8rem;
    }
  }
`;

export default function ImageBasedBorad({ contents }: {contents: {
  imageUrl: string;
  name: string;
}[]}) {
  return (
    <Places>
      {contents.map((content, i) => (
        <Place key={`${i + 1}`}>
          <Image1 url={content.imageUrl || ''} />
          <h4>{content.name}</h4>
        </Place>
      ))}
    </Places>
  );
}
