import { useQuery } from '@tanstack/react-query';

import { apiService } from '../services/ApiService';
import Academy from '../models/Academy';

export default function useFetchAcademy({ id }:
  { id: string }) {
  const { isLoading, error, data } = useQuery<Academy>({
    queryKey: ['academy'],
    queryFn: () => apiService.fetchAcademy(+id),
  });

  return { isLoading, error, data };
}
