import { apiService } from '../services/ApiService';

export default function useOGReplacer() {
  function extractHost(url: string) {
    const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
    const matches = url.match(regex);
    return matches ? matches[1] : null;
  }

  const toBookmark = async () => {
    const bookmarkLinks = document.querySelectorAll('a.bookmark') as NodeListOf<HTMLAnchorElement>;

    bookmarkLinks.forEach(async (link) => {
      const ogData = await apiService.fetchOgData(link.href);

      const ogCard = document.createElement('a');
      ogCard.className = 'og-card';
      ogCard.href = ogData.url || link.href;

      const image = document.createElement('img');
      image.src = ogData.image || 'default-image.jpg';
      image.alt = ogData.title || 'No title';
      image.className = 'og-img';
      ogCard.appendChild(image);

      const contents = document.createElement('div');
      contents.className = 'contents';

      const site = document.createElement('div');
      site.className = 'og-site';

      const favicon = document.createElement('img');
      const host = extractHost(ogData.url);
      console.log(ogData.favicon);
      favicon.src = `https://${host}${ogData.favicon}`;
      favicon.className = 'og-favicon';
      site.appendChild(favicon);

      const siteName = document.createElement('div');
      siteName.textContent = ogData.siteName;
      siteName.className = 'og-site-name';
      site.appendChild(siteName);
      contents.appendChild(site);

      const title = document.createElement('div');
      title.textContent = ogData.title || 'No title';
      title.className = 'og-title';
      contents.appendChild(title);

      const description = document.createElement('p');
      description.textContent = ogData.description || 'No description';
      description.className = 'og-description';
      contents.appendChild(description);

      ogCard.appendChild(contents);

      link.parentNode?.replaceChild(ogCard, link);
    });
  };

  return { toBookmark };
}
