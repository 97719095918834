import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Academy from '../components/Academy';

import useFetchAcademy from '../hooks/useFetchAcademy';

export default function AcademyPage() {
  const { id } = useParams();

  const { isLoading, error, data: academy } = useFetchAcademy(
    { id: id || '' },
  );

  if (isLoading) {
    return null;
  }

  if (error || !academy) {
    return <div>Error</div>;
  }

  const name = academy.name.split('\n')[0];

  return (
    <>
      <Helmet>
        <title>{`韓国語 ${academy.name || '語学堂'} | CLIK`}</title>
        <meta name="description" content={`語学堂を選択するのに最も重要な要素である学費、寮、奨学金の有無、寮の有無、地域を一目で確認することができます。 語学堂の語学コースに関する詳しい情報と語学堂の長所を見ることができます。 ${academy.overview.introduction || ''}`} />
        <meta name="keywords" content={`${name}, 韓国語語学堂, 韓国語教育機関, 言語プログラム, 韓国語学習, 留学生, 韓国語教育, ソウル, 教育課程, 言語教育, 文化体験, 言語授業, 韓国語勉強, 留学生, 韓国文化, 言語プログラム登録, 語学堂の特徴`} />
      </Helmet>
      <Header />
      <Academy academy={academy} />
      <Footer />
    </>
  );
}
