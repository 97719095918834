import { useEffect, useState } from 'react';

export default function usePrograms({ initId }: {initId: number}) {
  const [selectedTabId, setSelectedTab] = useState(initId);

  const handleClick = (id: number) => {
    setSelectedTab(id);
  };

  useEffect(() => {
    setSelectedTab(initId);
  }, [initId]);

  return {
    selectedTabId,
    handleClick,
  };
}
