import { useState } from 'react';

export default function useCompareSelect() {
  const [selected, setSelected] = useState<number | null>(null);

  const [open, setStatus] = useState<boolean>(false);

  const flipOpen = () => {
    setStatus(!open);
  };

  const close = () => {
    setStatus(false);
  };

  const handleClick = (id: number | null) => {
    setSelected(id);
  };

  return {
    selected, handleClick, open, flipOpen, close,
  };
}
