import styled from 'styled-components';

import BigImage from '../common/BigImage';
import Contents from '../common/Contents';
import Section from '../common/Section';
import Title from '../common/Title';

import { Transport } from '../../models/Area';

const Wrapper = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: flex;
    gap: 3rem;

    * {
      flex: 1;
    }
  }  
`;

const Image = styled(BigImage)`
  border: 1px solid #E5E5E5;
`;

export default function Transport({ transport }: {transport : Transport}) {
  return (
    <Section>
      <Title>交通</Title>
      <Wrapper>
        <Image src={transport.imageUrl} height="auto" alt="" />
        <Contents marginBottom>{transport.contents}</Contents>
      </Wrapper>
    </Section>
  );
}
