import { ChangeEvent } from 'react';

import styled from 'styled-components';
import RadioButton from './RadioButton';
import AcademyItem from '../../models/AcademyItem';
import Option from './Option';

const Container = styled.div`
  position: relative;
  border-radius: 0 0 5rem 5rem;

  ul {
    max-height: 22.5rem;
    overflow: scroll;
    
  }
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 0 0 2rem 2rem;
`;

type OptionsProps = {
  academies: AcademyItem[]
  handleRegionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  region: string;
  select: (id:number) => void;
}

export default function Options({
  academies, handleRegionChange, region, select,
}: OptionsProps) {
  return (
    <Container>
      <Box>
        <RadioButton
          options={['ソウル', 'ちほう']}
          handleChange={handleRegionChange}
          selected={region}
        />
        <ul>
          {academies.map((a) => (
            <Option
              key={a.id}
              academy={a}
              handleClick={select}
            />
          ))}
        </ul>
      </Box>
    </Container>
  );
}
