import styled from 'styled-components';

import Place from '../../models/Place';
import Image1 from './Image1';

const Places = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5%;

  li {
    margin-bottom: 1.6rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Place = styled.li`
  line-height: 1.5;

  h3 {
    font-size: 1.6rem;
    margin-block: 1rem;
  }

  a {
    padding-right: 2.1rem;
    background: url(/images/icons/link.png) no-repeat right 35% / 1.6rem;
  }
  
  p {
    font-size: 1.6rem;
    word-break: break-all;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 30%;
    a,p {
      font-size: 1.8rem;
    }
  }
`;

export default function ImageBasedBoardWithContents({ contents }: {contents: {
  imageUrl: string;
  name: string;
  contents: string;
  url: string;
}[]}) {
  return (
    <Places>
      {contents.map((content, i) => (
        <Place key={`${i + 1}`}>
          <Image1 url={content.imageUrl || ''} />
          <h3><a href={content.url} target="_blank" rel="noreferrer">{content.name}</a></h3>
          <p>{content.contents}</p>
        </Place>
      ))}
    </Places>
  );
}
