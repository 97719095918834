import { useEffect, useRef, useState } from 'react';

export default function useStickyTab() {
  const [isSticky, setSticky] = useState(false);
  const [stickyPosition, setStickyPosition] = useState(0);
  const tabRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tabRef.current) {
      setStickyPosition(tabRef.current.offsetTop);
    }
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;

    if (scrollTop > stickyPosition - 64) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickyPosition]);

  return {
    isSticky, tabRef,
  };
}
