import { useEffect } from 'react';

type target = {
  lat: number,
  lon: number,
}

export default function Map({ lat, lon }:target) {
  useEffect(() => {
    const { naver } = window;
    if (!naver) return;

    const mapOptions = {
      center: new naver.maps.LatLng(lat, lon),
      zoom: 17,
    };

    const map = new naver.maps.Map('map', mapOptions);

    new naver.maps.Marker({
      position: new naver.maps.LatLng(lat, lon),
      map,
    });
  }, []);

  return (
    <div id="map" style={{ flex: 1, aspectRatio: '1/1' }} />
  );
}
