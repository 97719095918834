import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Home from '../components/Home';
import useFetchAcademies from '../hooks/useFetchAcademies';
import useFetchAreas from '../hooks/useFetchAreas';

export default function HomePage() {
  const { isLoading: areaLoading, data: areas } = useFetchAreas(3);
  const { isLoading: academyLoading, data: academies } = useFetchAcademies(3);

  if (areaLoading || academyLoading) {
    return null;
  }

  if (!areas || !academies) {
    return <div>Error</div>;
  }

  return (
    <>
      <Helmet>
        <title>HOME | CLIK</title>
        <meta name="description" content="韓国留学や語学の準備をお手伝いするCLIKです。 CLIKはCampus Life In Koreaの略で、韓国で新しい生活と挑戦を夢見ている人々に必要な情報とサービスを提供しています。 韓国で知ったらいい情報をたくさん用意しているので、たくさんの関心をお願いします。" />
        <meta name="keywords" content="韓国語 語学堂 比較, 韓国 地域 比較, ソウル 語学堂, ソウル 地域 情報, 韓国語 学習, 韓国語 留学, 韓国語 教育, ソウル 大学 語学堂, 語学堂 プログラム, 語学堂 申請, 韓国 留学 情報, 韓国 生活 情報, 大学 語学堂 比較, 韓国語 学習 コース, 韓国語 授業, 語学堂 入学, 語学堂 費用, 語学堂 レビュー, 語学堂 口コミ, 語学堂 登録, 語学堂 おすすめ" />
      </Helmet>
      <Header />
      <Home areas={areas} academies={academies} />
      <Footer />
    </>
  );
}
