import { useQuery } from '@tanstack/react-query';

import { apiService } from '../services/ApiService';

import Academy from '../models/Academy';

export default function useFetchAcademies(count?: number | undefined) {
  const { isLoading, error, data } = useQuery<Academy[]>({
    queryKey: ['academies'],
    queryFn: () => apiService.fetchAcademies(count),
  });

  return { isLoading, error, data };
}
