import Academy from '../../models/Academy';
import Cards from '../common/Cards';
import Section from '../common/Section';
import Title from '../common/Title';

type NearAcademiesProps = {
  academies: Academy[]
}

export default function NearAcademies({ academies }: NearAcademiesProps) {
  const cards = academies.map(({
    id, name, imageUrl,
  }) => {
    const [title, subTitle] = name.split('\n');
    return ({
      id,
      title,
      subTitle,
      imageUrl,
    });
  });

  return (
    <Section>
      <Title>周辺地域</Title>
      <Cards category="academy" cards={cards} />
    </Section>
  );
}
