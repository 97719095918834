import styled from 'styled-components';

const Title = styled.h3`
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: ${(props) => props.theme.sizes.inlineOffset};

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: 3.2rem;
  }
`;

export default Title;
