import styled from 'styled-components';

import { Program as ProgramModel } from '../../models/Academy';

import Schedules from './Schedules';

import Contents from '../common/Contents';
import SmallTitle from '../common/Small';

import Price from './Price';

const Wrapper = styled.div`
  padding: 4.8rem ${(props) => props.theme.sizes.inlineOffset};
  border-bottom: 1rem solid #F0F0F0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-inline: 0;
    border: 0;
  }
`;

const Title = styled.h4`
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: ${(props) => props.theme.sizes.inlineOffset}
`;

const Points = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
`;

type PointType = {
  colors: string[];
};

const Point = styled.li<PointType>`
  font-size: 1.4rem;
  padding: .4rem .8rem;
  background: ${(props) => props.colors[1]};
  color: ${(props) => props.colors[0]};
  border-radius: .8rem;
  border: 1px solid rgba(229, 229, 229, 1);
`;

const LimitWidthContents = styled(Contents)`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 60rem;
  }
`;

const GrayRoundDl = styled.dl`
  margin-block: .9rem 2.4rem;
  padding: ${(props) => props.theme.sizes.inlineOffset};
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 2rem;
  background: #F8F8F8;

  dt,dd {
    display: inline-block;
    line-height: 2;
  }

  dt {
    font-weight: 700;
    width: 30%;
  }

  dd {
    width: 70%;
    text-align: right;
  }

  a {
    display: block;
    margin-top: .8rem;
    padding-block: 1rem;
    width: 100%;
    color: #AB1F23;
    background: #FFD4D5;
    border: 0;
    border-radius: 1rem;
    text-align: center;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    border-radius: 1.5rem;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: .8rem;
  margin-bottom: 4rem;
  padding: 2rem ${(props) => props.theme.sizes.inlineOffset} 0;
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 3.2rem;
  background: #F8F8F8;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
  margin-bottom: 8rem;
  padding: 4rem 4rem 0;
  }
`;

const More = styled.a`
  display: block;
  margin-top: 4rem;
  padding-block: 1rem;
  width: 100%;
  color: #AB1F23;
  background: #FFD4D5;
  border: 0;
  border-radius: 1rem;
  text-align: center;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-inline: auto;
    width: 30%;
  }
`;

const Classes = styled.ul`
  margin-block: 2rem 4rem;
  padding: ${(props) => props.theme.sizes.inlineOffset};
  border-radius: 2rem;
  background: #F8F8F8;
  vertical-align: middle;
  border: 1px solid rgba(229, 229, 229, 1);

  :first-child {
    margin-top: 0;
  }

  :last-child .time {
    padding-bottom: 0;
    border-bottom: 0;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: flex;
    padding: 4rem;
  }
`;

const Keywords = styled.ul`
  display: inline-block;
  width: auto;
  vertical-align: middle;
`;

type KeywordType = {
  colors: string[];
}

const Keyword = styled.li<KeywordType>`
  font-size: 1.2rem;
  display: inline-block;
  margin-left: 1rem;
  padding: .5rem .4rem;
  background: grey;
  border-radius: .5rem;
  background: ${(props) => props.colors[1]};
  color: ${(props) => props.colors[0]};
`;

type ClassProps = {
  url: string;
};

const Class = styled.li<ClassProps>`
  position: relative;
  padding-top: 2rem;
  margin-top: 1.5rem;

  h4 {
    font-weight: 700;
    display: inline-block;
    padding-top: 1.6rem;
    color: rgba(153, 153, 153, 1);
  }

  .time {
    font-weight: 700;
    padding-block: 1.5rem;
    border-bottom: 1px solid rgba(216, 216, 216, 1);
  }

  &:before {
    position: absolute;
    top: 0rem;
    content: '';
    width: 2.5rem;
    height: 2rem;
    background: url(${(props) => props.url}) no-repeat center / contain;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: 0;
    width: 50%;

    h4 {
      font-size: 1.8rem;
    }

    .time {
      font-size: 2.4rem;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

type DLProps = {
  paddingBottom?: number | null;
  desktopPadding: number;
}

const DL = styled.dl<DLProps>`
  gap: .8rem;
  margin-bottom: 2rem;
  padding: 2rem ${(props) => props.theme.sizes.inlineOffset} ${(props) => props.paddingBottom || 0}rem;
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 2rem;
  background: #F8F8F8;

  dd {
    font-size: 2.8rem;
    font-weight: 700;
    padding-bottom: 2rem;
    line-height: 1.2;
    word-break: break-all;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: ${(props) => props.desktopPadding}rem;
    border-radius: 1.5rem;

    dt {
      font-size: 1.8rem;
    }

    dd {
      font-size: 2.4rem;
    }
  }
`;

type DTProps = {
  url: string;
}

const DTWithIcon = styled.dt<DTProps>`
  position: relative;
  font-weight: 700;
  margin-block: 3.5rem 1rem;
  color: rgba(153, 153, 153, 1);

  &:before {
    top: -3.5rem;
    position: absolute;
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2rem;
    background: url(${(props) => props.url}) no-repeat center / contain;
    color: #FFFFFF;
    border-radius: .5rem;
  }
`;

const DT = styled.dt`
  font-weight: 700;
  margin-block: 1rem;
  color: rgba(153, 153, 153, 1);

  &:first-of-type {
    margin-top: 0;
  }
`;

const DD = styled.dd`
  font-size: 2rem !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);

`;

const FlexBox = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: flex;
    gap: 4rem;
    margin-block: 8rem 4rem;
    
    .item {
      width: 50%;
    }
  }
`;

const Hint = styled.div`
  font-size: 1.4rem;
  margin-bottom: 4rem;
  color: rgba(153, 153, 153, 1);

  a {
    color: rgba(196, 33, 43, 1);
    text-decoration: underline;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-bottom: 8rem;
  }
`;

const DtDdWrapper = styled.div`
  margin-top: 2rem;
  border-bottom: 1px solid rgba(229, 229, 229, 1);

  dt {
    margin-top: 4.5rem;
  }
  
  dd {
    margin-bottom: 2rem;
  }

  &:first-of-type {
    margin-top: 0;
    dd {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    border-bottom: 0;

    dd {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: inline-block;
    margin: 0;
    width: 50%;
    border: 0;

    dt {
      margin-top: 6.5rem;
      padding-bottom: 0;
    }

    dd {
      font-size: 2.4rem !important;
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`;

function getPointColor(label: string) {
  switch (label) {
  case '10週間のコース':
  case '夏の特別コース':
  case '9週間のコース':
  case '8週間のコース':
  case '3週間のコース':
  case '1週間のコース':
  case '4週間のコース':
  case '2週間のコース': return ['rgba(46, 0, 178, 1)', 'rgba(46, 0, 178, 0.2)'];

  case 'ステップ1-7':
  case 'ステップ1-6':
  case 'ステップ1-4':
  case 'ステップ1-5':
  case '7段階':
  case '一般過程':
  case '夜間課程': return ['rgba(4, 178, 0, 1)', 'rgba(4, 178, 0, 0.2)'];

  case '深化過程': return ['rgba(228, 0, 0, 1)', 'rgba(228, 0, 0, 0.2)'];

  case '奨学金': return ['rgba(0, 132, 228, 1)', 'rgba(0, 132, 228, 0.2)'];

  case '寄宿舎': return ['rgba(243, 146, 0, 1)', 'rgba(243, 146, 0, 0.2)'];

  default: return ['rgba(46, 0, 178, 1)', 'rgba(46, 0, 178, 0.2)'];
  }
}

const Division = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    gap: 2rem;
  }
`;

type ProgramType = {
  selectedYear: string;
  selectedId: string;
  program: ProgramModel
}

const priceName = {
  applicationFee: '申請料',
  tuition: '授業料',
  AdmissionFee: '入学金',
};

const classIcon = {
  午前のクラス: '/images/icons/morning.svg',
  午後クラス: '/images/icons/afternoon.svg',
};

export default function Program({ selectedYear, selectedId, program }: ProgramType) {
  const { points } = program;

  return (
    <Wrapper>
      <Title id={`${program.id}`}>{program.name}</Title>
      <Points>
        {points.map((p) => (
          <Point key={p} colors={getPointColor(p)}>
            {p}
          </Point>
        ))}
      </Points>
      <LimitWidthContents>{program.introduction}</LimitWidthContents>
      <Division>
        <div>
          <SmallTitle>費用</SmallTitle>
          <Box>
            <dl>
              {Object.entries(program.fees).map(([k, v]) => (
                <Price key={k} name={priceName[k]} amount={v} blockEmptySize={2} />
              ))}
            </dl>
          </Box>
          <Schedules
            selectedYear={selectedYear}
            selectedId={selectedId}
            schedules={program.schedules}
          />
        </div>
        <div>
          <SmallTitle>授業情報</SmallTitle>
          <DL desktopPadding={4} paddingBottom={2}>
            <DTWithIcon url="/images/icons/step.svg">ステップ</DTWithIcon>
            <dd>{program.education.contents}</dd>
            <DtDdWrapper>
              <DTWithIcon url="/images/icons/people2.svg">人数</DTWithIcon>
              <dd>{program.participants}</dd>
            </DtDdWrapper>
            <DtDdWrapper>
              <DTWithIcon url="/images/icons/calendar.svg">期間</DTWithIcon>
              <dd>{program.classPeriod}</dd>
            </DtDdWrapper>
          </DL>
          <Hint>
            * 詳細情報をご希望の場合は、
            {' '}
            <a
              href={program.education.curriculumLink}
              target="_blank"
              rel="noreferrer"
            >
              ここ
            </a>
            をクリックしてください。
          </Hint>
          <SmallTitle>授業時間</SmallTitle>
          <Classes>
            {
              program.classes.map((c) => (
                <Class key={c.name} url={classIcon[c.name] || ''}>
                  <h4>{c.name}</h4>
                  <Keywords>
                    {c.keywords.map((k) => (
                      <Keyword key={k} colors={getPointColor(k)}>{k}</Keyword>
                    ))}
                  </Keywords>
                  <div className="time">{c.time}</div>
                </Class>
              ))
            }
          </Classes>
          <FlexBox>
            <div className="item">
              <SmallTitle>奨学制度</SmallTitle>
              <DL paddingBottom={2} desktopPadding={2}>
                {!program.scholarship ? 'なし' : program.scholarship.map(([k, v]) => (
                  <>
                    <DT>{k}</DT>
                    <DD>{v}</DD>
                  </>
                ))}
              </DL>
            </div>
            <div className="item">
              <SmallTitle>寄宿舎</SmallTitle>
              <GrayRoundDl>
                <div>{program.dormitory.contents}</div>
              </GrayRoundDl>
              <Hint>
                詳細情報をご希望の場合は、
                <a
                  href={program.dormitory.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  ここ
                </a>
                をクリックしてください。
              </Hint>
            </div>
          </FlexBox>
          <SmallTitle>その他</SmallTitle>
          <DL paddingBottom={2} desktopPadding={2}>
            <DtDdWrapper>
              <DT>志願資格</DT>
              <DD>{program.requirements}</DD>
            </DtDdWrapper>
            <DtDdWrapper>
              <DT>必要ビザ</DT>
              <DD>{program.visas.join(', ')}</DD>
            </DtDdWrapper>
          </DL>
        </div>
        <More href={program.link}>
          もっと調べよう
        </More>
      </Division>
    </Wrapper>
  );
}
