import styled from 'styled-components';

type BoxProps = {
  url: string;
}

const Box = styled.div<BoxProps>`
  position: relative;
  height: 28rem;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${(props) => props.url}) no-repeat center / cover;

  h2 {
    font-size: 2.8rem;
    position: absolute;
    left: ${(props) => props.theme.sizes.inlineOffset};
    bottom: 0;
    color: #FFFFFF;

    span {
      font-size: 2rem;
      display: block;
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    border-radius: 3rem;
    height: 34rem;

    h2 {
      left: 4rem;
      font-size: 4rem;

      span {
        3.2rem;
      }
    }
  }
`;

type TopSectionProps = {
  imageUrl: string;
  name1: string;
  name2: string;
}

export default function TopSection({ imageUrl, name1, name2 }: TopSectionProps) {
  return (
    <Box url={imageUrl}>
      <h2>
        {name1}
        <span>{name2}</span>
      </h2>
    </Box>
  );
}
