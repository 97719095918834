import { useQuery } from '@tanstack/react-query';

import { apiService } from '../services/ApiService';

import Area from '../models/Area';

export default function useFetchAreas(count?: number | undefined) {
  const { isLoading, error, data } = useQuery<Area[]>({
    queryKey: ['areas'],
    queryFn: () => apiService.fetchAreas(count),
  });

  return { isLoading, error, data };
}
