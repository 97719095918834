import styled from 'styled-components';
import Place from '../../models/Place';
import ImageBasedBoardWithContents from '../common/ImageBasedBoardWithContents';
import Section from '../common/Section';
import Title from '../common/Title';

const NoBorderSection = styled(Section)`
  border-bottom: 0;
`;

export default function Places({ places }: {places: Place[]}) {
  return (
    <NoBorderSection>
      <Title>ホットプレイス</Title>
      <ImageBasedBoardWithContents contents={places} />
    </NoBorderSection>
  );
}
