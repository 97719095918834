const defaultTheme = {
  colors: {
    primary: '#E16B1A',
    background: '#FFFFFF',
    shader: '#F6F6F6',
    text: '#151515',
  },
  breakpoints: {
    desktop: 980,
  },
  sizes: {
    layoutMaxWidth: '980px',
    inlineOffset: '2rem',
  },
};

export default defaultTheme;
