import styled from 'styled-components';
import numberFormat from '../../utils/numberFormat';

type ContainerProps = {
  blockEmptySize: number;
}

const Container = styled.div<ContainerProps>`
  margin-top: 6rem;

  dt {
    position: relative;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: rgba(153, 153, 153, 1);
  }

  dt:before {
    top: -3rem;
    position: absolute;
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background: url(/images/icons/won.svg) no-repeat center / contain;
    color: #FFFFFF;
    border-radius: .5rem;
  }

  dd {
    font-size: 2.8rem;
    font-weight: 700;
  }

  &:first-of-type {
    margin-top: 3rem;
  }

  &:last-of-type {
    padding-bottom: ${(props) => props.blockEmptySize}rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: inline-block;
    margin-top: 0;
    padding-bottom: 4rem;
    width: 50%;
  }
`;

type PriceProps = {
  name: string;
  amount: number;
  blockEmptySize?: number;
};

export default function Price({ name, amount, blockEmptySize = 4 }:PriceProps) {
  return (
    <Container blockEmptySize={blockEmptySize}>
      <dt>{name}</dt>
      <dd>{`${numberFormat(amount)} ₩`}</dd>
    </Container>
  );
}
