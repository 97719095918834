import styled from 'styled-components';
import AcademyItem from '../../models/AcademyItem';

const Item = styled.li`
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  padding: 2rem;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
`;

type OptionProps = {
  academy: AcademyItem;
  handleClick: (id: number) => void;
}

export default function Option({ academy, handleClick }: OptionProps) {
  const {
    id, name, area,
  } = academy;
  return (
    <Item key={id} onClick={() => handleClick(id)}>
      <span>{name.split('\n')[0]}</span>
      <span>{area.split('\n')[0]}</span>
    </Item>
  );
}
