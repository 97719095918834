import styled from 'styled-components';
import Cards2 from '../common/Cards2';
import Area from '../../models/Area';

const Container = styled.div`
  margin-block: 6.4rem ${(props) => props.theme.sizes.inlineOffset};
  padding-inline: ${(props) => props.theme.sizes.inlineOffset};

  h2 {
    font-size: 2.8rem;
    font-weight: 700;
    padding-block: ${(props) => props.theme.sizes.inlineOffset};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: 7.6rem;

    h2 {
      font-size: 3.2rem;
    }
  }
`;

type AreasProps = {
  areas: Area[];
}

export default function Areas({ areas }: AreasProps) {
  const cards = areas.map((a) => ({
    id: a.id,
    title: `${a.name.split('\n')[0]}`,
    subTitle: a.name.split('\n')[1],
    imageUrl: a.imageUrl,
  }));

  return (
    <Container>
      <h2>지역</h2>
      <Cards2 category="area" cards={cards} />
    </Container>
  );
}
