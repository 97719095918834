import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CompareAcademy from '../../models/CompareAcademy';
import numberFormat from '../../utils/numberFormat';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  img {
    width: 100%;
    border-radius: 1rem;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-block: 4rem;
  min-height: 10rem;
`;

const Label = styled.div`
  font-weight: 700;
  display: block;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const Contents = styled.div`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
`;

const Comment = styled.div`
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 1.5;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`;

const Line = styled.div`
  width: 50%;
  height: 1px;
  margin-inline: 3rem;
  background: rgba(229, 229, 229, 1);
`;

const LinkButton = styled(Link)`
  margin-top: 4rem;
  padding: 1rem 2rem;
  background: rgba(171, 31, 35, 1);
  border-radius: 1rem;
  color: #FFFFFF;
`;

type AcademyCompareProps = {
  compareAcademy: CompareAcademy
}

const feeWords = {
  tuition: '選考料',
  applicationFee: '受験料',
  admissionFee: '入学金',
};

export default function AcademyCompare({ compareAcademy }: AcademyCompareProps) {
  const {
    id, name, price, area, classPeriod, existAdvanceClass, advantages, hasRelationship, link,
  } = compareAcademy;

  const [weeks, times] = classPeriod.split(' / ');
  return (
    <Container>
      <img src={compareAcademy.imageUrl} alt="" />
      <Box>
        <Label>費用</Label>
        <Contents>{`${numberFormat(price.tuition)}₩`}</Contents>
        {Object.entries(price).filter(([k, v]) => v && k !== 'tuition').map(([k, v]) => (
          <Comment key={k}>{`${feeWords[k]} ${numberFormat(v)}₩`}</Comment>
        ))}
      </Box>
      <Line />
      <Box>
        <Label>位置</Label>
        <Contents>{area.split('\n')[0]}</Contents>
      </Box>
      <Line />
      <Box>
        <Label>期間</Label>
        <Contents>{weeks}</Contents>
        <Comment>{`총${times}`}</Comment>
      </Box>
      <Line />
      <Box>
        <Label>特典</Label>
        {advantages.map((a) => (
          <Contents key={a}>{a}</Contents>
        ))}
      </Box>
      <Line />
      <Box>
        <Label>韓国人交流プログラム</Label>
        <Contents>{hasRelationship ? '有り' : 'なし'}</Contents>
      </Box>
      <Line />
      <LinkButton to={`/academy/${id}`}>詳しく見る</LinkButton>
    </Container>
  );
}
