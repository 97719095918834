import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    gap: 2%;
    flex-wrap: wrap;
  }
`;

type CardProps = {
  url: string;
}

const Card = styled.li<CardProps>`
  position: relative;
  height: 24rem;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${(props) => props.url}) no-repeat center / cover;
  border-radius: 2rem;

  a {
    display: inline-block;
    padding: 1.5rem;
    padding-top: 17.5rem;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  p {
    font-size: 1.6rem;
    margin-top: .6em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-bottom: 2rem;
    width: 32%;

    h3 {
      font-size: 2.4rem;
      color: #FFFFFF;
    }

    a {
      height: 16rem;
      padding-top: 17rem;
    } 

    p {
      font-size: 1.8rem;
      color: #FFFFFF;
      
    }
  }
`;

const Contents = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    
  }
`;

type CardModel = {
  id: number;
  title: string;
  subTitle: string;
  imageUrl: string;
}

type CardsProps = {
  category: string;
  cards: CardModel[];
};

export default function Cards2({ category, cards }: CardsProps) {
  return (
    <Container>
      {cards.map((card) => (
        <Card key={card.id} url={card.imageUrl}>
          <Link to={`/${category}/${card.id}`}>
            <Contents>
              <h3>{card.title}</h3>
              <p>{card.subTitle}</p>
            </Contents>
          </Link>
        </Card>
      ))}
    </Container>
  );
}
