import styled from 'styled-components';
import AcademyCompare from './AcademyCompare';
import CompareAcademyResult from '../../models/CompareAcademyResult';
import Vote from './Vote';
import AcademyItem from '../../models/AcademyItem';
import CompareProgramType from '../../models/CompareProgramType';

const Container = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 8rem;

  >* {
    flex: 1;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    gap: 10rem;
  }
`;

type ResultProps = {
  academies: AcademyItem[];
  result: CompareAcademyResult | null;
  programType: CompareProgramType;
}

export default function CompareResult({
  result, academies, programType,
}: ResultProps) {
  if (!result) {
    return null;
  }

  return (
    <>
      <Container>
        <AcademyCompare compareAcademy={result.academies[0]} />
        <AcademyCompare compareAcademy={result.academies[1]} />
      </Container>
      <Vote
        academies={academies}
        popularities={result.popularites}
        programType={programType}
      />
    </>
  );
}
