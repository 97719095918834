import styled from 'styled-components';

const ArticleArea = styled.div`
    margin-block: 4rem;

    &> * {
      margin-inline: 2.4rem;
    }

    section {
      margin-bottom: 4rem;
    }

    h2 {
      margin-bottom: 2rem;
    }

    h3 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 2rem;
    }

    .bold {
      font-weight: 700;
    }

    .small {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }

    .hint {
      font-size: 1.2rem;
    }

    .underline {
      display: inline-block;
      position: relative;
    }

    .underline::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 20%;
      width: 100%;
      height: 30%;
      background: #FFC2C4;
      z-index: -1;
    }

    ul {
      list-style: disc;
    }

    nav {
      background: rgba(248, 248, 248, 1);
      border-radius: 3rem;
      padding: 2rem;
      margin-bottom: 2rem;
    }

    nav h3 {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 2rem;
      padding-left: 2.6rem;
      background: url(/images/icons/arrow_up.png) no-repeat center left / 1.6rem 1.2rem;
    }

    nav ol {
      margin-left: 4rem;
    }

    nav > ol > li {
      font-size: 1.8rem;
    }

    .emphasis {
      position: relative;
      background-color: #f9f9f9; /* 배경 색상 */
      padding: 10px; /* 패딩 */
      padding-left: 2rem; /* 세로선 공간 확보 */
      line-height: 1.3;

      li {
        margin-left: 1.5rem;
        line-height: 1.6;
      }
    }

    .emphasis::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px; /* 세로선 너비 */
      background-color: #8B0000; /* 세로선 색상 */
    }

    .box {
      background: rgba(248, 248, 248, 1);
      border-radius: 3rem;
      padding: 2rem;
      margin-bottom: 2rem;

      li {
        margin-left: 1.5rem;
        line-height: 1.6;
      }
    }

    .red {
      background: #FFE5E5;
    }

    .blue {
      background: #E5F1FF;
    }

    .yellow {
      background: #FFF6E5;
    }

    .green {
      background: #E5FFEE;
    }

    .pink {
      background: #FFEEFF;
    }

    .grey {
      background: #F8F8F8;
    }

    .italic {
      font-style: italic;
      }

    > img {
      aspect-ratio: 382/270;
      width: 100%;
      border-radius: 3rem;
    }


    @media screen and (min-width: 980px) {
      font-size: 1.8rem;
      margin-block: 8rem;

      section {
        margin-bottom: 8rem;
      }

      &> * {
        margin-inline: 0rem;
      }
      
      h2 {
        font-size: 4rem;
      }

      h3 {
        font-size: 3.2rem;
      }
      
      h4 {
        font-size: 2.4rem;
      }

       p {
        margin-bottom: 4rem;
      }

      .small {
        font-size: 1.6rem;
      }

      .hint {
        font-size: 1.4rem;
      }
        
      nav {
        padding-block: 4rem;
      }

      nav h3 { 
        font-sizeL 2.4rem;
      }

      > img {
        aspect-ratio: 640/468;
        width: 64rem;
        border-radius: 3rem;
      }


      .og-card {
        width: 100%;
        border: 1px solid rgba(229, 229, 229, 1);
      }

      table {
          width: 50%;
          border-collapse: collapse;
      }
      th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: center;
      }
      th {
          background-color: #f2f2f2;
      }
`;
export default ArticleArea;
