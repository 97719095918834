import { useQuery } from '@tanstack/react-query';

import { apiService } from '../services/ApiService';

import AcademyItem from '../models/AcademyItem';

export default function useFetchCompareAcademies() {
  const { isLoading, error, data } = useQuery<AcademyItem[]>({
    queryKey: ['compare-academies'],
    queryFn: () => apiService.fetchCompareAcademies(),
  });

  return { isLoading, error, data };
}
