import { useQuery } from '@tanstack/react-query';

import { apiService } from '../services/ApiService';

import Area from '../models/Area';

export default function useFetchArticles(count?: number | undefined) {
  const { isLoading, error, data } = useQuery<any>({
    queryKey: ['contents'],
    queryFn: () => apiService.fetchArticles(),
  });

  return { isLoading, error, data };
}
