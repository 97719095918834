import { Helmet } from 'react-helmet';

import ArticleHeader from '../../../styles/ArticleHeader';
import ArticleArea from '../../../styles/ArticleArea';

/* eslint-disable max-len */

export default function Article1() {
  const title = '外国人登録証とは❓';
  const description = '外国人登録証とは、ビザを取得して韓国に滞在する外国人の身分を証明する重要な書類です。必要書類や発行の流れについて説明します。';
  const url = '/images/article/article1_cover.jpg';
  const tags = ['外国人登録証', '韓国', 'ビザ', '必要書類', '発行手続き'];
  const date = '2024.06.24';
  return (
    <ArticleArea>
      <Helmet>
        <meta name="description" content={description} />
        <meta name="keywords" content={tags.join(', ')} />
        <title>{`${title} | CLIK`}</title>
      </Helmet>
      <ArticleHeader url={url}>
        <div className="date">{date}</div>
        <h2>{title}</h2>
        <ul className="tags">
          {tags.map((t) => (<li key={t}>{`#${t}`}</li>))}
        </ul>
      </ArticleHeader>
      <p className="header-text">外国人登録証とは、ビザを取得して韓国に滞在する外国人の身分を証明する重要な書類です。留学にかかわらず結婚、ワーキングホリデー、仕事や出張など９０日以上韓国に滞在する場合は発行が義務付けられています。外国人登録証はお酒を飲む際や、病院に行く際にも必須のため、日常的に持ち歩くことを推奨します🌷</p>
      <section>
        <h3>外国人登録証発行時の必要書類</h3>
        <ul className="emphasis">
          <li>外国人登録申請書(ハイコリアのホームページよりダウンロード可能)</li>
          <li>パスポート</li>
          <li>手数料３万₩</li>
          <li>登録用の写真1枚(縦4.5x横3.5cm白色の背景)</li>
          <li>現住所を証明できる書類(家の契約書など)</li>
          <li>その他、滞在資格別に必要とされる書類 ※D-4語学堂留学の場合、在学証明書など</li>
        </ul>
        <p className="hint">必要書類はビザの種類によって異なるため、訪問前に訪問予定の出入国管理庁にお問い合わせされることをおすすめします。書類は全て記載したうえで訪問した方がスムーズに進みます‼</p>
      </section>

      <section>
        <h3>外国人登録証発行の流れ</h3>
        <ul>
          <li>
            ハイコリアで管轄の出入国外国人庁に訪問予約 URLはこちら🙋
            <a href="https://www.hikorea.go.kr/" target="_blank" rel="noreferrer">https://www.hikorea.go.kr/</a>
          </li>
          <li>予約日時に訪問し、ATMで申請料の支払い、窓口で申請書類の提出</li>
        </ul>
        <p className="hint italic">※ 外国人登録証は直接受け取りか郵便配送か選択できます！(発行まで約１か月程度) 直接受け取りの場合は指定日時に再訪問する必要があり、郵便配送の場合は手数料が別途必要 ここまで終われば申請完了</p>
      </section>

      <section>
        <h3>外国人登録証が必要な場面</h3>
        <ul>
          <li>銀行の口座開設</li>
          <li>携帯電話の契約、購入</li>
          <li>住居の契約</li>
          <li>保険契約</li>
          <li>インターネット契約</li>
          <li>オンオフラインでの会員加入</li>
          <li>飲酒時の年齢確認や病院薬局での身分確認等</li>
        </ul>
      </section>

      <section>
        <h3>注意点⚠</h3>
        <ul>
          <li>外国人登録完了前に韓国から出国した場合、ビザが抹消される可能性があります。</li>
          <li>入国から９０日以内に発行しないと罰金が科せられます。</li>
          <li>再申請をする場合は必ず外国人登録証の有効期限が切れる前に申請が必要です。</li>
          <li>日本に完全帰国する際は、出国する際空港で外国人登録証を返却する必要があります。</li>
        </ul>
      </section>

      <section>
        <h3 className="underline">最後に🌸</h3>
        <p>
          <span>韓国に留学して意外と忘れがちな外国人登録証💦</span>
          外国人登録証は、韓国に入国してからでないと予約できず、特に入学人数が多い春学期などは1か月以上の予約待ちになることも(;_;) 更に申請後、受け取りまで約1か月程度かかります😢銀行開設や携帯電話、家の契約時に必要なことが多く、外国人登録証が無いとかなり不便なため90日以上韓国に滞在予定のある方は、韓国入国後すぐにハイコリアからお住まいの管轄の出入国外国人庁の訪問予約をし、外国人登録証の申請をされることをおすすめします😊
        </p>
      </section>
    </ArticleArea>
  );
}
