import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    gap: 2%;
  }
`;

type CardProps = {
  url: string;
}

const Card = styled.li<CardProps>`
  position: relative;
  margin-bottom: 2rem;
  padding-top: 16.2rem;
  width: 100%;
  height: 24rem;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${(props) => props.url}) no-repeat center / cover;
  border-radius: 2rem;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    color: #FFFFFF;
  }

  h3 {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .contents {
    font-weight: 700;
    line-height: 1.1
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 32%;
  }
`;

type CardModel = {
  id: number;
  title: string;
  subTitle: string;
  imageUrl: string;
}

type CardsProps = {
  category: string;
  cards: CardModel[];
};

export default function Cards3({ category, cards }: CardsProps) {
  return (
    <Container>
      {cards.map((card) => (
        <Card key={card.id} url={card.imageUrl}>
          <Link to={`/${category}/${card.id}`}>
            <h3>{card.title}</h3>
            <div className="contents">{card.subTitle}</div>
          </Link>
        </Card>
      ))}
    </Container>
  );
}
